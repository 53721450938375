'use strict';

import http from '@/utils/http';

export default {
  // 日程分页
  classifyUrl: '/schedule-activities/page',
  // getTreeUrl: '/category/getCategorySon',
  getTreeUrl: '/category/getCategoryTree',
  // 新增日程
  add(code, data) {
    return http.post('/schedule-activities', data, { code });
  },
  // 编辑日程
  update(code, data) {
    return http.put('/schedule-activities', data, { code });
  },
  // 删除日程
  delete(code, params) {
    return http.delete('/schedule-activities', { params, code });
  },
  saveListToRole(code, data) {
    return http.post('/authority/role/saveAppClientRoleAuthority', data, {
      code,
    });
  },
  getList(code, params) {
    return http.get('/authority/applicationClient/list', { params, code });
  },

  // 获取下拉数据
  getCategoryTree(code, params) {
    return http.get('/category/getCategorySon', {
      code,
      params,
    });
  },
  // 日程审核
  auditSchedule(code, data) {
    return http.post('/schedule-activities/auditSchedule', data, {
      code,
    });
  },
  // 查询日程
  getActivities(code, id) {
    return http.get(`/schedule-activities/${id}`, { code });
  },

  //   发布日程
  publish(code, data) {
    return http.post('/schedule-activities/publish', data, {
      code,
    });
  },
};
