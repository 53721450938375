<template>
  <a-modal
    :visible="visible"
    :title="form.disabled ? '查看' : form.id ? '编辑' : '新增'"
    :mask-closable="false"
    :width="750"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 20 }"
      ref="formRef"
      :model="form"
    >
      <a-form-item label="语言" v-bind="validateInfos.language">
        <a-radio-group
          v-model:value="form.language"
          :options="languageList"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="活动类型" v-bind="validateInfos.activityType">
        <a-select
          v-model:value="form.activityType"
          placeholder="请选择活动类型"
          :disabled="form.disabled"
          @change="handleActivityType($event)"
        >
          <a-select-option
            v-for="item in activityTypeList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--   需要查一遍id   -->
      <a-form-item label="专题类型" v-bind="validateInfos.topicType">
        <a-select
          v-model:value="form.topicType"
          placeholder="请选择专题类型"
          :disabled="form.disabled"
          @change="handleTopicType($event)"
        >
          <a-select-option
            v-for="item in topicTypeList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--   需要查一遍id    -->
      <a-form-item label="专题标签" v-bind="validateInfos.topicLabel">
        <a-select
          v-model:value="form.topicLabel"
          placeholder="请选择专题标签"
          :disabled="form.disabled"
        >
          <a-select-option
            v-for="item in topicLabelList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否有直播" v-bind="validateInfos.isLive">
        <a-select
          v-model:value="form.isLive"
          placeholder="请选择"
          :disabled="form.disabled"
        >
          <a-select-option
            v-for="item in isLiveList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="会议形式" v-bind="validateInfos.meetingFormat">
        <a-select
          v-model:value="form.meetingFormat"
          placeholder="请选择会议形式"
          :disabled="form.disabled"
        >
          <a-select-option
            v-for="item in meetingFormatList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="直播ID" v-bind="validateInfos.liveId">
        <a-input
          v-model:value="form.liveId"
          placeholder="请输入直播ID"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="标题" v-bind="validateInfos.title">
        <a-input
          v-model:value="form.title"
          placeholder="请输入标题"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="日期" v-bind="validateInfos.activity">
        <!-- format="YYYY-MM-DD" -->
        <a-date-picker
          v-model:value="form.activity"
          format="YYYY-MM-DD"
          show-time
          type="date"
          placeholder="选择日期"
          style="width: 100%"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="开始时间" v-bind="validateInfos.startTime">
        <a-date-picker
          v-model:value="form.startTime"
          show-time
          type="date"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择时间"
          style="width: 100%"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="结束时间" v-bind="validateInfos.endTime">
        <a-date-picker
          v-model:value="form.endTime"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="选择时间"
          style="width: 100%"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="活动简介" v-bind="validateInfos.activityIntroduction">
        <a-textarea
          v-model:value="form.activityIntroduction"
          placeholder="请输入活动简介"
          show-count
          :maxlength="800"
          :disabled="form.disabled"
        />
      </a-form-item>
      <a-form-item label="跳转链接" v-bind="validateInfos.jumpLink">
        <a-input
          v-model:value="form.jumpLink"
          placeholder="请输入跳转链接"
          :disabled="form.disabled"
        />
      </a-form-item>

      <a-form-item label="是否上首页" v-bind="validateInfos.isHome">
        <a-switch
          v-model:checked="form.isHome"
          checked-children="是"
          un-checked-children="否"
          :checked-value="Number(1)"
          :un-checked-value="Number(0)"
        />
      </a-form-item>
      <a-form-item label="日程图片" v-bind="validateInfos.bannerUrl">
        <iss-image-upload
          v-model:value="form.bannerUrl"
          :limit-size="1"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
        <div class="font-color font-size">
          建议上传16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch, ref, onBeforeUnmount } from 'vue';
import { Form, Select, DatePicker, Radio, Switch } from 'ant-design-vue';
import classifyApi from '@/api/classify';
import IssImageUpload from '@/components/imageUpload';
import moment from 'moment';
// import { useRoute } from 'vue-router';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADatePicker: DatePicker,
    ASwitch: Switch,
    ARadioGroup: Radio.Group,
    IssImageUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    // const route = useRoute();
    // const campaignId = route.params.campaignId;
    const editorRef = ref();
    const formRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      activityType: '',
      language: 'Chinese',
      topicType: '',
      topicLabel: '',
      startTime: null,
      endTime: null,
      activity: '',
      activityIntroduction: '',
      title: '',
      jumpLink: '',
      isLive: '',
      liveID: '',
      isHome: 0,
      meetingFormat: '',
      bannerUrl: '',
    });
    const state = reactive({
      activityTypeList: [],
      topicTypeList: [],
      topicLabelList: [],
      isLiveList: [
        { label: '没有', value: '0' },
        { label: '有', value: '1' },
      ],
      meetingFormatList: [
        { label: '请选择', value: '' },
        { label: '线上', value: '线上' },
        { label: '线下', value: '线下' },
        { label: '线上+线下', value: '线上+线下' },
      ],

      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
    });
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      language: [required],
      activityType: [required],
      startTime: [required],
      endTime: [required],
      activity: [required],
      title: [required, { max: 200, message: '嘉宾姓名不能超过200个字符' }],
      // activityIntroduction: [required],
      jumpLink: [
        { type: 'url', message: '请输入正确的URL地址，以http或者https开头' },
      ],
      guestImg: [{ type: 'url', message: '请输入正确的链接格式' }],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
          handleActivityType(form.activityType);
          handleTopicType(form.topicType);
        } else {
          resetFields();
        }
      }
    );

    watch(
      () => props.visible && form.language,
      value => {
        form.language = value;
        getTree(value);
      }
    );

    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });

    const handleActivityType = id => {
      // 活动类型（一级id）
      classifyApi
        .getCategoryTree('', {
          language: form.language,
          moduleCode: 'meeting',
          parentId: id,
        })
        .then(data => {
          form.topicType = '';
          form.topicLabel = '';
          state.topicTypeList = data;
          state.topicTypeList.activity = moment(state.topicTypeList.activity)
            .format('YYYY-MM-DD')
            .toString();
          state.topicTypeList.startTime = moment(state.topicTypeList.startTime)
            .format('YYYY-MM-DD HH:mm:ss')
            .toString();
          state.topicTypeList.endTime = moment(state.topicTypeList.endTime)
            .format('YYYY-MM-DD HH:mm:ss')
            .toString();
        });
    };

    const handleTopicType = id => {
      // 活动类型（二级id）拿三级数据
      classifyApi
        .getCategoryTree('', {
          language: form.language,
          moduleCode: 'meeting',
          parentId: id,
        })
        .then(data => {
          form.topicLabel = '';
          state.topicLabelList = data;
        });
    };

    const getTree = () => {
      // 获取下拉数据
      classifyApi
        .getCategoryTree('', {
          language: form.language,
          moduleCode: 'meeting',
        })
        .then(data => {
          state.activityTypeList = data;
        });
    };
    const handleOk = () => {
      form.activity = moment(form.activity).format('YYYY-MM-DD').toString();
      form.startTime = moment(form.startTime)
        .format('YYYY-MM-DDTHH:mm:ss')
        .toString();
      form.endTime = moment(form.endTime)
        .format('YYYY-MM-DDTHH:mm:ss')
        .toString();
      console.log(form.startTime);
      if (form.disabled) {
        form.disabled = false;
        context.emit('update:visible', false);
      } else {
        form.disabled = false;
        validate()
          .then(() => {
            context.emit('fnOk', toRaw(form));
          })
          .catch(res => {
            console.log(res);
            if (res.errorFields.length == 0) {
              handleOk();
            }
          });
      }
    };

    return {
      handleActivityType,
      handleTopicType,
      ...toRefs(state),
      form,
      validateInfos,
      formRef,
      handleCancel: () => {
        form.disabled = false;
        context.emit('update:visible', false);
      },

      handleOk,
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
